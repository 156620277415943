import { Select } from 'antd';
import React, { memo } from 'react';
import { StepList, Steps, StepsLocalisation } from '../../types';

const { Option } = Select;

export type StepPickerLocalisation = StepsLocalisation & {
  placeholder?: string;
};

export const defaultLocalisation: StepPickerLocalisation = {
  days: 'Days',
  weeks: 'Weeks',
  months: 'Months',
  year: 'Year',
  placeholder: 'Select step',
};

export interface StepPickerParams {
  defaultValue?: Steps;
  localisation?: StepPickerLocalisation;
  onChange: (stepKey: Steps) => void;
}

export const StepPicker = memo(
  ({ defaultValue, localisation, onChange = () => {} }: StepPickerParams) => {
    return (
      <Select
        defaultValue={defaultValue}
        onChange={onChange}
        placeholder={
          localisation?.placeholder || defaultLocalisation.placeholder
        }
      >
        {StepList.map((step) => (
          <Option key={step} value={step}>
            {localisation?.[step] || defaultLocalisation[step]}
          </Option>
        ))}
      </Select>
    );
  }
);
