import moment from 'moment';
import React, { FunctionComponent, memo } from 'react';
import { Config } from '../../interfaces';
import { stepsBetween } from '../../utilities';
// import { Droppable } from '../droppable';
import styles from './styles.module.scss';

type BaseLaneProps = {
  laneId: string;
  startDate: Config['startDate'];
  endDate: Config['endDate'];
  steps: Config['steps'];
  rows?: number;
  baseGridStyle?: React.CSSProperties;
  showGridItems?: boolean;
  gridItemStyle?: React.CSSProperties;
};

export const BaseLane: FunctionComponent<BaseLaneProps> = memo(
  ({
    laneId,
    startDate,
    endDate,
    steps,
    children,
    baseGridStyle = {},
    rows = 1,
    showGridItems = true,
    gridItemStyle = {},
  }) => {
    // We always use days for grid steps - to support the smallest size to paint.
    const gridSteps = stepsBetween(startDate, endDate, 'days');

    return (
      <div
        style={{
          gridTemplateColumns: `repeat(${gridSteps}, 1fr)`,
          gridTemplateRows: `repeat(${rows}, 1fr)`,
        }}
        className={styles.grid}
      >
        {showGridItems && (
          <GridItems
            laneId={laneId}
            gridSteps={gridSteps}
            startDate={startDate}
            steps={steps}
            rows={rows}
            gridStyle={baseGridStyle}
            gridItemStyle={gridItemStyle}
          />
        )}
        {children}
      </div>
    );
  }
);

const getStepsGridArea = (
  date: moment.Moment,
  column: number,
  steps: Config['steps']
) => {
  let endColumn = 1;
  if (steps === 'months') {
    endColumn = 1 + date.daysInMonth() - date.date();
  } else if (steps === 'weeks') {
    endColumn = 1 + 7 - date.day();
  }
  return `${column} / ${column + endColumn}`;
};

const GridItems = memo(
  ({
    laneId,
    gridSteps,
    startDate,
    steps,
    rows,
    gridStyle: baseGridStyle,
    gridItemStyle = {},
  }: {
    laneId: string;
    gridSteps: number;
    startDate: Config['startDate'];
    steps: Config['steps'];
    rows: number;
    gridStyle: React.CSSProperties;
    gridItemStyle?: React.CSSProperties;
  }) => {
    const items: any = [];

    for (let row = 1; row <= rows; row++) {
      for (let i = 0; i < gridSteps; i++) {
        const date = moment(startDate).add(i, 'days');
        const isoString = date.toISOString();
        if (
          i === 0 ||
          steps === 'days' ||
          steps === 'year' ||
          (steps === 'weeks' && date.day() === 1) ||
          (steps === 'months' && date.date() === 1)
        ) {
          const column = i + 1;
          const gridArea = {
            gridRow: `${row} / ${row + 1}`,
            gridColumn: getStepsGridArea(date, column, steps),
          };

          const isOdd = i % 2 !== 0;
          let style = isOdd
            ? { ...gridArea, ...gridItemStyle }
            : { ...gridArea, ...baseGridStyle, ...gridItemStyle };

          if (steps === 'year') {
            style.minWidth = '10px';
          }

          items.push(
            // <Droppable
            //   id={`grid-item-${laneId}.${row}.${column}`}
            //   key={`grid-item-${laneId}.${row}.${column}`}
            //   className={styles.gridItem}
            //   style={style}
            // >
            <div
              key={`grid-item-${laneId}.${row}.${column}`}
              className={`${styles.gridItem} ____gridItem_${laneId}____${isoString}____`}
              style={style}
            ></div>
            // </Droppable>
          );
        }
      }
    }

    return items;
  }
);
