import { Activity } from '..';
import { DimensionProps } from '../types/dimension-properties';
import { groupBy } from './group-by';

export const groupActivitiesByDimensions = (
  activities: Activity<any>[],
  dimensions: string[]
): DimensionProps[] => {
  const [dimension, ...leftOverDimensions] = dimensions;
  const activitiesGrouped = groupBy(
    activities,
    (activity) => activity.properties[dimension]
  );

  return activitiesGrouped.reduce<any>((acc, cv) => {
    const title = cv[0].properties[dimension];

    const item: DimensionProps = {
      dimension: title,
      activities: cv,
      children: null,
    };
    if (leftOverDimensions.length > 0) {
      item.children = groupActivitiesByDimensions(cv, leftOverDimensions);
    }

    acc.push(item);
    return acc;
  }, []);
};

