import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import moment from 'moment';
import React, { memo, useState, useCallback, useMemo } from 'react';
import { useCtrlKey } from '../../utilities/useCtrlKey';
import { ActivityItem, DatetimeLane, DimensionNode, NowLine } from '..';
import { DimensionProps } from '../..';
import { Activity, Config } from '../../interfaces';
import { groupActivitiesByDimensions } from '../../utilities';
import styles from './styles.module.scss';

export interface PlanboardParams {
  activities: Array<Activity<any>>;
  defaultDimensions?: Array<string>;
  actionBoxItem?: React.ReactNode;
  config: Config;
  sorter?: (a: DimensionProps, b: DimensionProps) => number;
  onChangeActivity?: (activity: Activity<any>) => void;
  onAddActivity?: (activity: Activity<any>) => void;
}

export const Planboard = memo(
  ({
    activities,
    actionBoxItem,
    config,
    defaultDimensions = [],
    sorter = () => 0,
    onChangeActivity,
    onAddActivity = () => {},
  }: PlanboardParams) => {
    const [draggingItem, setDraggingItem] = useState<Activity<any>>();
    const [tmpCopyActivities, setTmpCopyActivities] = useState<Activity<any>[]>(
      []
    );

    const visibleActivities = activities.filter(
      (activity) =>
        activity.startDate < config.endDate &&
        activity.endDate > config.startDate
    );

    const groupedActivities = useMemo(() => {
      const tmpActivities = [...visibleActivities, ...tmpCopyActivities];

      const tmpGroupedActivities = groupActivitiesByDimensions(
        tmpActivities,
        config.dimensions
      );

      defaultDimensions.forEach((dimension) => {
        if (
          tmpGroupedActivities.findIndex(
            (act) => act.dimension === dimension
          ) === -1
        )
          tmpGroupedActivities.push({
            dimension: dimension,
            activities: [],
            children: null,
          });
      });
      return tmpGroupedActivities;
    }, [visibleActivities]);

    const sensors = useSensors(
      useSensor(MouseSensor, {
        activationConstraint: {
          distance: 5,
        },
      })
    );

    const { ctrlKeyPressed } = useCtrlKey();

    const handleDragStart = (event) => {
      setDraggingItem(
        visibleActivities.find((activity) => activity.id === event.active.id)
      );
    };

    const handleDragMove = (event) => {
      if (
        typeof event?.active?.id === 'string' &&
        (event.active.id.includes('dragdown-') ||
          event.active.id.includes('dragup-'))
      ) {
        // console.log(event);
        const [, , draggingId] =
          /drag(down|up)-(.+)/.exec(event.active.id) || [];

        const startActivity = visibleActivities.find(
          (activity) => activity.id + '' === draggingId
        );

        if (startActivity) {
          // console.log(startActivity);
          const startRow =
            startActivity.properties[
              config.dimensions[config.dimensions.length - 1]
            ];

          const allRows = groupedActivities.map((group) => group.dimension);

          let startIndex = allRows.findIndex((row) => row === startRow);

          const element = document
            .elementsFromPoint(
              event.activatorEvent.x,
              event.activatorEvent.y + event.delta.y
            )
            .find((element) => element.className.includes('gridItem'));

          if (element) {
            const [, dimensionValues] =
              (element &&
                /____gridItem_(.+)____(.+)____/.exec(element.className)) ||
              [];

            const splitDimensionValues = dimensionValues.split('-.-');
            let endIndex = allRows.findIndex(
              (row) =>
                row === splitDimensionValues[splitDimensionValues.length - 1]
            );

            if (startIndex > endIndex) {
              startIndex -= 1;
            } else if (startIndex < endIndex) {
              startIndex += 1;
            } else {
              return;
            }

            const copyToRows = allRows.slice(
              Math.min(startIndex, endIndex),
              Math.max(startIndex, endIndex) + 1
            );

            setTmpCopyActivities(
              copyToRows.map((row) => {
                const tmpActivity: Activity<any> = {
                  ...startActivity,
                  id: Math.random(),
                  properties: {
                    ...startActivity.properties,
                    [config.dimensions[config.dimensions.length - 1]]: row,
                  },
                };
                return tmpActivity;
              })
            );
          }
        }
      }
    };

    const handleDragEnd = (event) => {
      if (draggingItem && onChangeActivity) {
        const element = document
          .elementsFromPoint(
            event.active.rect.current.translated.left,
            event.active.rect.current.translated.top
          )
          .find((element) => element.className.includes('gridItem'));
        const [, dimensionValues, isoString] =
          (element &&
            /____gridItem_(.+)____(.+)____/.exec(element.className)) ||
          [];
        const difference = draggingItem.endDate.diff(
          draggingItem.startDate,
          'days'
        );
        if (dimensionValues) {
          const splitDimensionValues = dimensionValues.split('-.-');
          const lastDimensionValue =
            splitDimensionValues[splitDimensionValues.length - 1];
          const changedActivity: Activity<any> = {
            ...draggingItem,
            startDate: moment(isoString),
            endDate: moment(isoString).add(difference, 'days'),
            properties: {
              ...draggingItem.properties,
              [config.dimensions[config.dimensions.length - 1]]:
                lastDimensionValue,
            },
          };

          if (ctrlKeyPressed) {
            changedActivity.id = undefined;
            onAddActivity(changedActivity);
          } else {
            onChangeActivity(changedActivity);
          }
        }
      }
      setDraggingItem(undefined);
      if (tmpCopyActivities) {
        tmpCopyActivities.forEach((act) => {
          onAddActivity({ ...act, id: undefined });
        });
      }
      setTmpCopyActivities([]);
    };

    return (
      <div className={styles.planboard}>
        <div className={styles.mainboard}>
          <div className={styles.toolbar}>
            <div
              className={styles.toolbarDimensions}
              style={{
                width: `${config.dimensions.length * 180 || 180}px`,
              }}
            >
              {actionBoxItem}
            </div>

            <DatetimeLane
              startDate={config.startDate}
              endDate={config.endDate}
              steps={config.steps}
            />
          </div>
          <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragMove={handleDragMove}
          >
            {groupedActivities.sort(sorter).map((activityGroup) => (
              <DimensionNode
                key={`node-${activityGroup.dimension}`}
                dimension={activityGroup}
                config={config}
              />
            ))}
            <DragOverlay>
              {draggingItem && (
                <ActivityItem
                  style={draggingItem.style}
                  // text={draggingItem.activityType}
                ></ActivityItem>
              )}
            </DragOverlay>
          </DndContext>
          <NowLine config={config} />
        </div>
      </div>
    );
  }
);

