import { Popover } from 'antd';
import React, { memo, ReactNode } from 'react';
import { ActivityStyle } from '../../types';
import { ConditionalWrapper } from '../../utilities';
import styles from './styles.module.scss';

type ActivityItemProps = {
  style?: ActivityStyle;
  text?: string;
  popoverContent?: ReactNode;
  popoverTitle?: string;
  onClick?: () => void;
  children?: ReactNode;
};
export const ActivityItem = memo(
  ({
    style,
    text = '',
    onClick,
    popoverContent,
    popoverTitle,
    children,
  }: ActivityItemProps) => {
    return (
      <ConditionalWrapper
        wrapContent={!!popoverContent}
        wrapper={
          <Popover
            mouseLeaveDelay={0}
            content={popoverContent}
            title={popoverTitle}
          />
        }
      >
        <div
          style={{
            background: style?.background || '#ccc',
            color: style?.color || '#fff',
            borderRadius: `${style?.cutOffStart ? '0px' : '10px'} ${
              style?.cutOffEnd ? '0px 0px' : '10px 10px'
            } ${style?.cutOffStart ? '0px' : '10px'}`,
            cursor: onClick ? 'pointer' : 'default',
          }}
          className={styles.UIActivity}
          onClick={onClick}
        >
          {text}
          {children}
        </div>
      </ConditionalWrapper>
    );
  }
);

