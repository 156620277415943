import moment from 'moment';
import { Config } from '../interfaces';

export const stepsBetween = (
  startDate: moment.Moment,
  endDate: moment.Moment,
  steps: Config['steps']
) => {
  if (!moment.isMoment(endDate)) {
    endDate = moment(endDate);
  }
  return endDate.diff(startDate, steps);
};
