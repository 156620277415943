export function groupBy<T>(
  list: Array<T>,
  getter: (item: T) => any
): Array<Array<T>> {
  const grouped = list.reduce((acc, item) => {
    const value = getter(item);
    acc[value] = acc[value] ? [...acc[value], item] : [item];
    return acc;
  }, Object.create(null));
  return Object.values(grouped);
}
