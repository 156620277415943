import moment from 'moment';
import React, { memo } from 'react';
import { BaseLane } from '..';
import { Config } from '../../interfaces';
import { stepsBetween } from '../../utilities';
import styles from './styles.module.scss';

export const DatetimeLane = memo(
  ({
    startDate,
    endDate,
    steps,
  }: {
    startDate: Config['startDate'];
    endDate: Config['endDate'];
    steps: Config['steps'];
  }) => {
    const gridSteps = stepsBetween(startDate, endDate, 'days');
    const childItems = createDateGridItems(gridSteps, startDate, steps);

    return (
      <BaseLane
        startDate={startDate}
        endDate={endDate}
        steps={steps}
        rows={3}
        laneId="datetime-baselane"
      >
        {...childItems}
      </BaseLane>
    );
  }
);

const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

const getDateFormatter = (step: string) => {
  const formats = {
    days: (date: moment.Moment) => date.format('D'),
    weeks: (date: moment.Moment) => `W${date.format('WW')}`,
    months: (date: moment.Moment) =>
      capitalizeFirstLetter(date.format('MMMM YYYY')),
    year: (_date: moment.Moment) => '',
  };
  return formats[step] || formats['days'];
};

const createDateGridItems = (
  gridSteps: number,
  startDate: moment.Moment,
  steps: Config['steps']
): JSX.Element[] => {
  const childItems: JSX.Element[] = [];

  const dateFormatter = getDateFormatter(steps);
  const weekFormatter = getDateFormatter('weeks');
  const monthFormatter = getDateFormatter('months');

  for (let i = 0; i < gridSteps; i++) {
    const date = moment(startDate).add(i, 'days');
    const column = i + 1;

    if (steps === 'days') {
      childItems.push(
        <span
          className={styles.datetimeLaneItem}
          key={`3 / 4 ${column} / ${column + 1}`}
          style={{
            gridColumn: `${column} / ${column + 1}`,
            gridRow: '3 / 4',
          }}
        >
          {dateFormatter(date)}
        </span>
      );

      if (date.day() === 1) {
        // It's the first day in the week; add the week item
        childItems.push(
          <span
            className={styles.datetimeLaneItem}
            key={`2 / 3 ${column} / ${column + 7}`}
            style={{
              gridColumn: `${column} / ${column + 7}`,
              gridRow: '2 / 3',
            }}
          >
            {weekFormatter(date)}
          </span>
        );
      }
      if (date.date() === 1) {
        // It's the first day in the month; add the month item
        childItems.push(
          <span
            className={styles.datetimeLaneItem}
            key={`1 / 3 ${column} / ${column + date.daysInMonth()}`}
            style={{
              gridColumn: `${column} / ${column + date.daysInMonth()}`,
              gridRow: '1 / 3',
            }}
          >
            {monthFormatter(date)}
          </span>
        );
      }
    } else if (steps === 'weeks') {
      if (i === 0 || date.day() === 1) {
        // It's the first day in the week; add the week item
        childItems.push(
          <span
            className={styles.datetimeLaneItem}
            key={`3 ${column} / ${column + 7}`}
            style={{
              gridColumn: `${column} / ${column + 7}`,
              gridRow: 3,
            }}
          >
            {weekFormatter(date)}
          </span>
        );
      }
      if (date.date() === 1) {
        // It's the first day in the month; add the month item
        childItems.push(
          <span
            className={styles.datetimeLaneItem}
            key={`2 / 4 ${column} / ${column + date.daysInMonth()}`}
            style={{
              gridColumn: `${column} / ${column + date.daysInMonth()}`,
              gridRow: '2 / 4',
            }}
          >
            {monthFormatter(date)}
          </span>
        );
      }
    } else if (steps === 'months') {
      if (i === 0 || date.date() === 1) {
        // It's the first day in the month; add the month item
        childItems.push(
          <span
            className={styles.datetimeLaneItem}
            key={`3 ${column} / ${column + date.daysInMonth()}`}
            style={{
              gridColumn: `${column} / ${column + date.daysInMonth()}`,
              gridRow: 3,
            }}
          >
            {monthFormatter(date)}
          </span>
        );
      }
    } else if (steps === 'year') {
      childItems.push(
        <span
          className={styles.datetimeLaneItem}
          key={`3 / 4 ${column} / ${column + 1}`}
          style={{
            gridColumn: `${column} / ${column + 1}`,
            gridRow: '3 / 4',
          }}
        >
          {dateFormatter(date)}
        </span>
      );

      if (date.day() === 1) {
        // It's the first day in the week; add the week item
        childItems.push(
          <span
            className={styles.datetimeLaneItem}
            key={`2 / 3 ${column} / ${column + 7}`}
            style={{
              gridColumn: `${column} / ${column + 7}`,
              gridRow: '2 / 3',
            }}
          >
            {weekFormatter(date)}
          </span>
        );
      }
      if (date.date() === 1) {
        // It's the first day in the month; add the month item
        childItems.push(
          <span
            className={styles.datetimeLaneItem}
            key={`1 / 3 ${column} / ${column + date.daysInMonth()}`}
            style={{
              gridColumn: `${column} / ${column + date.daysInMonth()}`,
              gridRow: '1 / 3',
            }}
          >
            {monthFormatter(date)}
          </span>
        );
      }
    }
  }

  return childItems;
};

