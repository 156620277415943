import { useEffect, useState } from 'react';

export const useCtrlKey = () => {
  const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);

  useEffect(() => {
    const ctrlKeyListener = (pressed: boolean) => (event: KeyboardEvent) => {
      if (event.defaultPrevented) return;

      if (
        (event.key !== undefined &&
          (event.key === 'Control' || event.key === 'Meta')) ||
        (event.keyCode !== undefined && event.keyCode === 17)
      ) {
        setCtrlKeyPressed(pressed);
      }
    };

    const ctrlKeyDownListener = ctrlKeyListener(true);
    const ctrlKeyUpListener = ctrlKeyListener(false);

    document.addEventListener('keydown', ctrlKeyDownListener);
    document.addEventListener('keyup', ctrlKeyUpListener);

    return () => {
      document.removeEventListener('keydown', ctrlKeyDownListener);
      document.removeEventListener('keyup', ctrlKeyUpListener);
    };
  }, []);

  return { ctrlKeyPressed };
};
