import React, { memo } from 'react';
import { ActivityLane } from '..';
import { Config } from '../../interfaces';
import { DimensionProps } from '../../types/';
import { createGrid, createTemplateAreaString } from './grid-helper';
import styles from './styles.module.scss';

export const DimensionNode = memo(
  ({ dimension, config }: { dimension: DimensionProps; config: Config }) => {
    const gridStyle = createGrid(dimension);
    return (
      <div className={styles.dimensionWrapper} style={gridStyle}>
        <DimensionTitles
          dimension={dimension}
          onMouseOver={config.onDimensionTitleMouseOver}
          onMouseOut={config.onDimensionTitleMouseOut}
        />
        <ActivityLanes dimension={dimension} config={config} />
      </div>
    );
  }
);

const getDimensionTitles = (dimension: DimensionProps): any => {
  const titles = [dimension.dimension];

  const getChildren = (children) => {
    if (!children) {
      return [];
    }
    const sub: any = [];
    for (let child of children) {
      sub.push(child.dimension);
      sub.push(...getChildren(child.children));
    }
    return sub;
  };

  titles.push(...getChildren(dimension.children));

  return titles;
};

const DimensionTitles = ({
  dimension,
  onMouseOver = () => {},
  onMouseOut = () => {},
}: {
  dimension: DimensionProps;
  onMouseOver?: Config['onDimensionTitleMouseOver'];
  onMouseOut?: Config['onDimensionTitleMouseOut'];
}) => {
  const titles = getDimensionTitles(dimension);
  return titles.map((title) => (
    <div
      className={styles.dimensionTitleWrapper}
      style={{
        gridArea: createTemplateAreaString(title),
      }}
      title={title}
      key={title}
    >
      <div
        className={styles.dimensionTitleContainer}
        onMouseOver={(event) => onMouseOver(dimension.dimension, event)}
        onMouseOut={(event) => onMouseOut(dimension.dimension, event)}
      >
        <div className={styles.dimensionTitle}>{title}</div>
      </div>
    </div>
  ));
};

const ActivityLanes = ({
  dimension,
  config,
  parentNodes = [],
  expandOverlap = true,
}: {
  dimension: DimensionProps;
  config: Config;
  parentNodes?: string[];
  expandOverlap?: boolean;
}) => {
  const nodes = [...parentNodes, dimension.dimension];
  if (!dimension.children) {
    // Render single activity lane
    const key = nodes.join('-.-');
    return (
      <>
        {dimension.activities && (
          <div key={key} className={styles.activityLaneWrapper}>
            <ActivityLane
              id={key}
              activities={dimension.activities}
              startDate={config.startDate}
              endDate={config.endDate}
              steps={config.steps}
              popoverContent={config.contentOnActivityPopover}
              popoverContentTitle={config.contentOnActivityPopoverTitle}
              onActivityClick={config.onActivityEdit}
              expandOverlap={expandOverlap}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {dimension.children.map((dim) => (
        <ActivityLanes dimension={dim} config={config} parentNodes={nodes} />
      ))}
    </>
  );
};
