import React, { FunctionComponent, ReactElement } from 'react';

export type ConditionalWrapperProps = {
  wrapContent: boolean;
  wrapper: ReactElement<any>;
};

const ConditionalWrapper: FunctionComponent<ConditionalWrapperProps> = ({
  wrapContent,
  wrapper,
  children,
}) => {
  return (
    <>{wrapContent ? React.cloneElement(wrapper, {}, children) : children}</>
  );
};

export { ConditionalWrapper };
