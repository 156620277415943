import { DimensionProps } from '../../types';

const getRowspan = (dimension: DimensionProps, start: number = 0) => {
  if (!dimension.children) {
    return start + 1;
  }
  return dimension.children.reduce((acc, cv) => {
    return acc + getRowspan(cv, start);
  }, start);
};

const getMaxDepth = (dimension: DimensionProps, start: number = 1) => {
  if (!dimension.children) {
    return start;
  }
  let max = start;
  for (let child of dimension.children) {
    const depth = getMaxDepth(child, start + 1);
    if (depth > max) {
      max = depth;
    }
  }
  return max;
};

const createTemplateAreas = (
  dimension: DimensionProps,
  parentNodes: string[] = []
) => {
  const nodes = [...parentNodes, createTemplateAreaString(dimension.dimension)];
  if (!dimension.children) {
    const key = nodes.join('-');
    return [[...nodes, 'al-' + createTemplateAreaString(key)].join(' ')];
  }

  return dimension.children
    .reduce<string[]>((acc, dim) => {
      const items = createTemplateAreas(dim, nodes);
      acc.push(...items);
      return acc;
    }, [])
    .flat();
};

export const createTemplateAreaString = (str: string) =>
  str ? str.replace(/^[^a-z]+|[^\w:.-]+/gi, '-').toLowerCase() : '';

export const createGrid = (dimension: DimensionProps) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${getMaxDepth(dimension)}, auto) 1fr`,
  gridTemplateRows: `repeat(${getRowspan(dimension)}, auto)`,
  gridTemplateAreas: `"${createTemplateAreas(dimension).join('"\n"')}"`,
});
