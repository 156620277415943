import React, { memo, ReactNode } from 'react';
import {
  DatePicker,
  DatePickerParams,
  NewActivityButton,
  StepPicker,
  StepPickerParams,
} from '..';
import styles from './styles.module.scss';

export interface HeaderParams {
  logo?: string;
  title: string;
  dateRangeValue: DatePickerParams['value'];
  onChangeDate?: DatePickerParams['onChange'];
  defaultStep?: StepPickerParams['defaultValue'];
  onChangeStep?: StepPickerParams['onChange'];
  buttons?: ReactNode[];
  localisation?: {
    steps?: StepPickerParams['localisation'];
  };
  onNewActivty?: () => void;
  disabledDate?: DatePickerParams['disabledDate'];
  format?: DatePickerParams['format'];
  ranges?: DatePickerParams['ranges'];
}

export const Header = memo(
  ({
    logo,
    title,
    dateRangeValue,
    buttons,
    onChangeDate = () => {},
    defaultStep,
    onChangeStep = () => {},
    localisation,
    onNewActivty = () => {},
    disabledDate,
    format,
    ranges,
  }: HeaderParams) => {
    return (
      <div className={styles.header}>
        <div className={styles.logo}>
          {logo && <img className={styles.logoImg} src={logo} alt="Logo" />}
        </div>
        <div className={styles.title}>{title}</div>
        <div className={styles.datePicker}>
          <DatePicker
            value={dateRangeValue}
            onChange={onChangeDate}
            disabledDate={disabledDate}
            format={format}
            ranges={ranges}
          />
        </div>
        <div className={styles.stepPicker}>
          Selecteer overzicht: &nbsp;&nbsp;
          <StepPicker
            localisation={localisation?.steps}
            defaultValue={defaultStep}
            onChange={onChangeStep}
          />
        </div>
        <div className={styles.filler} />
        {buttons &&
          buttons.map((button) => (
            <div className={styles.button}>{button}</div>
          ))}
        <div className={styles.button}>
          <NewActivityButton onClick={onNewActivty} />
        </div>
      </div>
    );
  }
);

