import React, { memo } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';

export interface NewActivityButtonProps {
  onClick?: () => void;
}

export const NewActivityButton = memo(
  ({ onClick = () => {} }: NewActivityButtonProps) => {
    return (
      <Button
        className={styles.activityButton}
        shape="round"
        type="primary"
        icon={<PlusOutlined />}
        onClick={onClick}
      >
        Nieuwe activiteit
      </Button>
    );
  }
);
