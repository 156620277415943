import React, { memo } from 'react';
import { DatePicker as AntdDatePicker } from 'antd';
import moment from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

const { RangePicker } = AntdDatePicker;

export interface DatePickerParams {
  value: RangeValue<moment.Moment>;
  onChange: (
    dates: RangeValue<moment.Moment>,
    dateStrings: [string, string]
  ) => void;
  disabledDate?: (currentMomemtDate: moment.Moment) => boolean;
  format?: string;
  ranges?: Record<
    string,
    | Exclude<RangeValue<moment.Moment>, null>
    | (() => Exclude<RangeValue<moment.Moment>, null>)
  >;
}

export const DatePicker = memo(
  ({
    value,
    onChange = () => {},
    disabledDate = () => false,
    format = 'D MMMM',
    ranges,
  }: DatePickerParams) => {
    return (
      value &&
      value[0] && (
        <RangePicker
          value={value}
          ranges={ranges}
          onChange={onChange}
          disabledDate={disabledDate}
          format={format}
        />
      )
    );
  }
);
