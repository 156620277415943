import React, { CSSProperties } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { useCtrlKey } from '../../utilities/useCtrlKey';
import styles from './styles.module.scss';

export const Draggable = ({ children, id, alwaysVisible = false }) => {
  const { ctrlKeyPressed } = useCtrlKey();

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: id,
    });
  const style: CSSProperties = {
    // transform: ctrlKeyPressed ? 'unset' : CSS.Translate.toString(transform),
    width: '100%',
    height: '100%',
    visibility:
      isDragging && !ctrlKeyPressed && !alwaysVisible ? 'hidden' : 'visible',
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      className={styles.draggable}
    >
      {children}
    </div>
  );
};
