import moment from 'moment';
import React, { memo } from 'react';
import { ActivityItem, BaseLane } from '..';
import { Activity, Config } from '../../interfaces';
import { stepsBetween } from '../../utilities';
import styles from './styles.module.scss';

import { ActivityItemConfiguration } from 'src/types/activity-item-configuration';
import { Draggable } from '../draggable';
// import { Droppable } from '../droppable';

type ActivityLaneProperties = {
  id: string;
  activities: Activity<any>[];
  startDate: moment.Moment;
  endDate: moment.Moment;
  steps: Config['steps'];
  popoverContent: Config['contentOnActivityPopover'];
  popoverContentTitle?: Config['contentOnActivityPopoverTitle'];
  onActivityClick?: (activity: Activity<any>) => void;
  expandOverlap: boolean;
};

export const ActivityLane = memo(
  ({
    id,
    activities,
    startDate,
    endDate,
    steps,
    popoverContent,
    popoverContentTitle = (activity) => activity.activityType,
    onActivityClick,
    expandOverlap,
  }: ActivityLaneProperties) => {
    const [items, rows] = createActivityItems(
      startDate,
      endDate,
      steps,
      activities,
      expandOverlap
    );
    return (
      // <Droppable id={id}>
      <BaseLane
        laneId={id}
        startDate={startDate}
        endDate={endDate}
        steps={steps}
        rows={rows}
        // baseGridStyle={{
        //   borderRight: '1px dashed #ccc',
        //   borderLeft: '1px dashed #ccc',
        // }}
      >
        {items.map((item) => (
          <div
            className={styles.activityLaneItemWrapper}
            style={{
              gridColumn: `${item.gridStartColumn} / ${item.gridEndColumn}`,
              gridRow: item.gridRow,
            }}
            key={item.activity.id}
          >
            <Draggable id={item.activity.id}>
              <ActivityItem
                popoverContent={
                  popoverContent ? popoverContent(item.activity) : ''
                }
                popoverTitle={popoverContentTitle(item.activity)}
                onClick={
                  onActivityClick
                    ? () => onActivityClick(item.activity)
                    : undefined
                }
                style={item.activity.style}
                // text={item.activity.activityType}
              >
                <div className={styles.dragUp}>
                  <Draggable id={'dragup-' + item.activity.id}>
                    <hr></hr>
                  </Draggable>
                </div>
                <div className={styles.dragDown}>
                  <Draggable id={'dragdown-' + item.activity.id}>
                    <hr></hr>
                  </Draggable>
                </div>
              </ActivityItem>
            </Draggable>
          </div>
        ))}
      </BaseLane>
      // </Droppable>
    );
  }
);

const getWeightedDate = (
  date: moment.Moment,
  steps: Config['steps'],
  isStart: boolean
) => {
  const newDate = date.clone();
  if (steps === 'days' || steps === 'year') {
    return newDate;
  }

  const period = steps === 'weeks' ? 'isoWeek' : 'month';
  return isStart ? newDate.startOf(period) : newDate.endOf(period);
};

const itemsOverlap = (
  a: ActivityItemConfiguration,
  b: ActivityItemConfiguration
) =>
  (a.gridStartColumn < b.gridStartColumn &&
    a.gridEndColumn > b.gridStartColumn) || // b starts in a
  (a.gridStartColumn < b.gridEndColumn && a.gridEndColumn > b.gridEndColumn) || // b ends in a
  (a.gridStartColumn >= b.gridStartColumn &&
    a.gridEndColumn <= b.gridEndColumn); // a is inside b

const createActivityItems = (
  startDate: moment.Moment,
  endDate: moment.Moment,
  steps: Config['steps'],
  activities: Activity<any>[],
  expandOverlap: boolean
): [ActivityItemConfiguration[], number] => {
  const items: ActivityItemConfiguration[] = [];
  let maxRow = 1;

  for (let activity of activities) {
    let gridStartColumn =
      stepsBetween(
        startDate,
        getWeightedDate(activity.startDate, steps, true),
        'days'
      ) + 1;

    if (gridStartColumn < 1) {
      gridStartColumn = 1;
      if (activity.style) {
        activity.style.cutOffStart = true;
      }
    }
    // We add + 2 here because the time is not taken into account and plan until and not including.
    // So if the endtime is 06th of february the planning is ended 05th of february. With +2 we also include the 6th.
    // This might be nessecary to change in the future.

    const gridEndColumn =
      stepsBetween(
        startDate,
        getWeightedDate(activity.endDate, steps, false),
        'days'
      ) + 2;

    if (activity.endDate >= endDate) {
      if (activity.style) {
        activity.style.cutOffEnd = true;
      }
    }
    const item = {
      activity,
      gridStartColumn,
      gridEndColumn,
      gridRow: 1,
    };

    if (expandOverlap) {
      const overlappingItems = items.filter(
        (x) => x.activity.id != activity.id && itemsOverlap(item, x)
      );
      if (overlappingItems.length) {
        const maxGridRow = Math.max(...overlappingItems.map((x) => x.gridRow));
        item.gridRow += maxGridRow;
      }
      if (item.gridRow > maxRow) {
        maxRow = item.gridRow;
      }
    }

    items.push(item);
  }
  return [items, maxRow];
};

