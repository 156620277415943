import React, { FC } from 'react';
import { Config } from '../../interfaces';
import { stepsBetween } from '../../utilities';
import { BaseLane } from '../base-lane';
import styles from './styles.module.scss';
import moment from 'moment';

export const NowLine: FC<{ config: Config }> = ({ config }) => {
  const now = moment();
  const isBetween = now >= config.startDate && now <= config.endDate;

  if (!isBetween) return null;

  const daysSinceStartDate = stepsBetween(config.startDate, now, 'days') + 1;

  return (
    <div className={styles.nowLineWrapper}>
      <div
        style={{
          height: '100%',
          width: `${config.dimensions.length * 180 || 180}px`,
        }}
      ></div>
      <BaseLane
        startDate={config.startDate}
        endDate={config.endDate}
        steps={config.steps}
        gridItemStyle={{ borderRight: 'unset' }}
        laneId="baselane-nowline"
      >
        <div
          id="NowLine"
          className={styles.nowLine}
          style={{
            gridArea: `1 / ${daysSinceStartDate} / 1 / ${
              daysSinceStartDate + 1
            }`,
          }}
        >
          <div className={styles.nowLineDot}></div>
        </div>
      </BaseLane>
    </div>
  );
};
