import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Select } from 'antd';
import React, { memo, useRef } from 'react';
import styles from './styles.module.scss';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    // xs: { span: 2 },
    sm: { span: 8 },
  },
  wrapperCol: {
    // xs: { span: 10 },
    sm: { span: 16 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    // xs: { span: 10, offset: 2 },
    sm: { span: 16, offset: 8 },
  },
};

export interface DimensionPickerParams {
  dimensions: { key: string; label: string }[];
  labels?: {
    title;
    addField;
  };
  defaultValues?: string[];
  onChange: (dimensionKeys: string[]) => void;
}

export const DimensionPicker = memo(
  ({
    dimensions = [],
    labels = {
      title: 'Dimensions',
      addField: 'Add field',
    },
    defaultValues,
    onChange,
  }: DimensionPickerParams) => {
    const [form] = Form.useForm();
    const prevDimensions = useRef([]);

    const onFinish = (store) => {
      const { dimensions } = store;
      const filteredDimensions = dimensions.filter(
        (dimension) => dimension !== undefined
      );
      if (filteredDimensions.join('.') !== prevDimensions.current.join('.')) {
        prevDimensions.current = filteredDimensions;
        onChange(filteredDimensions);
      }
    };

    return (
      <Form
        form={form}
        name="dynamic_form_item"
        {...formItemLayoutWithOutLabel}
        onFinish={onFinish}
        initialValues={{ dimensions: defaultValues }}
        className={styles.dimensionPicker}
      >
        <Form.List name="dimensions">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? labels.title : ''}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item {...field} noStyle>
                      <Select
                        size={'small'}
                        onSelect={form.submit}
                        style={{ width: 'calc( 100% - 40px )' }}
                      >
                        {dimensions.map((dimension) => (
                          <Option key={dimension.key} value={dimension.key}>
                            {dimension.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        style={{ margin: '0 8px' }}
                        onClick={() => {
                          remove(field.name);
                          setTimeout(() => {
                            form.submit();
                          });
                        }}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item
                  {...(fields.length === 0
                    ? formItemLayout
                    : formItemLayoutWithOutLabel)}
                  label={fields.length === 0 ? labels.title : ''}
                >
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc( 100% - 40px )',
                    }}
                    title={labels.addField}
                  >
                    <PlusOutlined /> {labels.addField}
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </Form>
    );
  }
);
